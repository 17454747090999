import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const YumOverlaysPage = () => {
  return (
    <Layout>
      <BlockWrapper block={{}}>
        <div>
          <h2 className="headline headline-h2 relative py-2">Left Leaning</h2>
          <h3 className="headline headline-h3 relative py-2">1by2 - thin</h3>
          <div className="w-24 bg-orange yum-overlay-1by2-left-leaning"></div>
          <code className="block pb-8">yum-overlay-1by2-left-leaning</code>
          <br />
          <div className="w-24 bg-magenta yum-overlay-1by2-left-leaning-filled-right"></div>
          <code className="block pb-8">
            yum-overlay-1by2-left-leaning-filled-right
          </code>
          <br />
          <div className="w-24 bg-green yum-overlay-1by2-left-leaning-filled-left"></div>
          <code className="block pb-8">
            yum-overlay-1by2-left-leaning-filled-left
          </code>
          <br />
          <hr />
          <h3 className="headline headline-h3 relative py-2">1by1 - bold</h3>
          <div className="w-48 bg-purple yum-overlay-1by1-left-leaning"></div>
          <code className="block pb-8">yum-overlay-1by1-left-leaning</code>
          <br />
          <div className="w-48 bg-lightblue yum-overlay-1by1-left-leaning-filled-right"></div>
          <code className="block pb-8">
            yum-overlay-1by1-left-leaning-filled-right
          </code>
          <br />
          <div className="w-48 bg-green yum-overlay-1by1-left-leaning-filled-left"></div>
          <code className="block pb-8">
            yum-overlay-1by1-left-leaning-filled-left
          </code>
          <br />

          <h2 className="headline headline-h2 relative py-2">Right Leaning</h2>
          <h3 className="headline headline-h3 relative py-2">1by2 - thin</h3>
          <div className="w-24 bg-orange yum-overlay-1by2-right-leaning"></div>
          <code className="block pb-8">yum-overlay-1by2-right-leaning</code>
          <br />
          <div className="w-24 bg-magenta yum-overlay-1by2-right-leaning-filled-right"></div>
          <code className="block pb-8">
            yum-overlay-1by2-right-leaning-filled-right
          </code>
          <br />
          <div className="w-24 bg-green yum-overlay-1by2-right-leaning-filled-left"></div>
          <code className="block pb-8">
            yum-overlay-1by2-right-leaning-filled-left
          </code>
          <br />
          <hr />
          <h3 className="headline headline-h3 relative py-2">1by1 - bold</h3>
          <div className="w-48 bg-purple yum-overlay-1by1-right-leaning"></div>
          <code className="block pb-8">yum-overlay-1by1-right-leaning</code>
          <br />
          <div className="w-48 bg-lightblue yum-overlay-1by1-right-leaning-filled-right"></div>
          <code className="block pb-8">
            yum-overlay-1by1-right-leaning-filled-right
          </code>
          <br />
          <div className="w-48 bg-green yum-overlay-1by1-right-leaning-filled-left"></div>
          <code className="block pb-8">
            yum-overlay-1by1-right-leaning-filled-left
          </code>
          <br />
        </div>
      </BlockWrapper>
    </Layout>
  )
}

export default YumOverlaysPage
